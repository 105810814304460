.App {
  text-align: center;
  /*background-color: #604c8e; */

}

.tm1 {
  position: relative;
  top: -50px;
}

.App-header {
  background-color: rgba(0, 0, 0, 0.95);
  min-height: 100vh;
  align-items: center;
  color: #ffffff;
  justify-content: center;
  width: auto;
  background-image: url('/background-02.jpg');
  background-repeat: no-repeat;
  background-size: cover;



 /*
display: flex;
flex-direction: column;



  font-size: calc(10px + 2vmin);
 */
}

.TopMarginSpace {
  margin-top: 10px;
}

.App-ClearSpacer {
  height: 10px;
  width:1px;
}
.App-HempchainLogo {
  animation: App-logo-spin infinite 20s linear;
  height: 20vmin;
  pointer-events: none;
  margin-top: 80px;
}

.App-HempchainTagline {
  font-style: italic;

  pointer-events: none;
  font-size: 1.2rem;
  margin-top: 10px;
}

.App-HempchainName {
  width: 70vmin;
  pointer-events: none;
}

p {
  padding-left: 2px;
  padding-right: 2px;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 20vmin;
  pointer-events: none;
}


.App-link {
  color: #2ed3fb;
  font-size: 1.2em;
  margin-left: 1px;
  margin-right: 1px;
  margin-top: 15px;
  padding-top: 10px;
  padding-right: 0px;
  padding-left: 0px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
